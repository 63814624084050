import ProjectsAPI from "../../api/projects/ProjectsAPI.js";
import { store } from "../FolioStore.js";
import { SectionTypesEnum } from "../../common/enums.js";

export function AddSection(postData, modal) {
    ProjectsAPI.addSection(
        store.state.project._id,
        postData
    )
        .then(response => {
            store.state.project = response.data;
            store.commit('selectSection', response.data.sections[postData.index]);
            if( postData.type === 'Header' || postData.type === 'Stat' || postData.type === 'Testimonial' ) {
                store.commit("updateNewSectionStyle", {position:postData.index, type: postData.type});
            }
            setTimeout(() => { store.commit("scrollToSection", postData.index); }, 10);
        })
        .catch(error => {})
        .finally(() => {
            updatePageInList()
            UpdateNavSection(postData.index, true);
            store.state.activeModal = !!modal ? modal : null;
        })
}

export function AddSuggestionSection(suggestionType) {
    let position = !!store.state.selectedSection ? store.state.selectedSection.index + 1 : 0;
    ProjectsAPI.addSuggestionSection(
        store.state.project._id,
        position,
        suggestionType
    )
        .then(response => {
            store.state.project = response.data;
            store.commit('selectSection', response.data.sections[position]);
            store.commit("scrollToSection", {sectionIndex:position});
            setTimeout(() => { store.commit("scrollToSection", {sectionIndex:position});
                }, 10);
        }).catch(error => {})
        .finally(() => {
            updatePageInList()
            UpdateNavSection(position, true);
            store.state.activeModal = null;
        });
}

export function UpdateSection(section, modal) {
    store.commit("updateSavingState");
    ProjectsAPI.updateSection(
        store.state.project._id,
        section
    )
        .then(response => {
            store.commit("updateSavingState");
        })
        .catch(response => { })
        .finally(() => {
            if(!!modal && modal === 'keepModalOpen') return;
            store.state.activeModal = !!modal ? modal : null;
        });
}

export function UpdateSections(sections) {
    store.commit("updateSavingState");
    ProjectsAPI.updateSections(
        store.state.project._id,
        sections
    )
        .then(response => {
            store.commit("updateSavingState");
        })
        .catch(response => { })
        .finally(() => {});
}

export function ArchiveSection(projectId, sectionIndex) {
    ProjectsAPI.archiveSection(
        projectId,
        sectionIndex
    )
        .then(response => {
            store.state.project.sections[sectionIndex].deletedAt = response.data.deletedAt;

            store.state.selectedSection = null;
        })
        .catch(error => {})
        .finally(() => {
            if(!store.state.project.sections[sectionIndex].deletedAt) store.commit("scrollToSection", {sectionIndex:sectionIndex});
            store.state.activeModal = null;
            UpdateNavSection(sectionIndex)
        })
}

export function ReorderSections(sections) {
    ProjectsAPI.reorderSections(
        store.state.project._id,
        sections
    )
        .then(response => {
            store.state.project = response.data;
        })
        .catch(response => {})
        .finally(() => store.state.activeModal = null)
}

export function DeleteProjectImage(imageUrl, croppedImageUrl) {
    if(imageUrl || croppedImageUrl) {
        ProjectsAPI.deleteProjectImage(
            store.state.project._id,
            imageUrl,
            croppedImageUrl
        )
            .then(response => {})
            .catch(response => {})
    }
}

export function DeleteGalleryItem(item) {
    ProjectsAPI.deleteGalleryItem(
        store.state.project._id,
        store.state.selectedSection.index,
        item
    )
        .then((response) => {
            store.commit('updateGalleryItems', response.data.galleryItems);
            store.state.project.sections[store.state.selectedSection.index].galleryItems = Object.assign([], response.data.galleryItems);
            store.commit('showAlertMessage',{type:'success', message:'Image successfully deleted.'});
            store.commit("scrollToItem", item);
        }).catch((response) => { });
}

export function UpdateNavSection(compromisedIndex, newSection) {
    if(store.state.project) {
        let allowUpdate = false;
        // find all navigation / process sections in project
        let processSections = store.state.project.sections.filter(s => s.type === SectionTypesEnum.NAVIGATION && !s.deletedAt)

        if(processSections.length === 0) return

        processSections.reduce((result, processSection) => {

            let navItems = processSection.navigationItems

            // if a linked section is deleted
            if( !newSection && !!navItems.find(i => i.sectionIndex === compromisedIndex) ) {
                store.state.selectedNavStep = navItems.findIndex(i => i.sectionIndex === compromisedIndex)
                store.state.selectedSection = processSection;
                store.commit('showAlertMessage',{
                    type:'success',
                    message:`Linked section ${!newSection ? 'deleted' : 'changed'}.`,
                    interval: 60
                });
                navItems.find(i => i.sectionIndex === compromisedIndex).sectionIndex = null;

                allowUpdate = true
            } else if (
                newSection &&
                !!navItems.findLast((e) => !!e.sectionIndex) &&
                compromisedIndex <= navItems.findLast((e) => !!e.sectionIndex).sectionIndex
            ) { // if the new section is after a process section and before a last linked section
                navItems.forEach(i => {
                    if(compromisedIndex <= i.sectionIndex) i.sectionIndex = i.sectionIndex + 1;
                })

                allowUpdate = true
            }

        }, null)

        if(allowUpdate) {

            return UpdateSections(processSections)
        }

    }
}

function updatePageInList() {
    if(!store.getters.isCaseStudy) {
        store.commit("updateEditedPageSectionsInPortfolioPages")
    } else {
        store.commit("updateEditedCaseStudySectionsInCaseStudyPages")
    }
}