<template>
    <AnimationWrapper
                :animation-style="$store.getters.animationSettings && $store.getters.animationSettings.animationStyle !== 'NONE' ? 'FADE_IN' : null"
                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                :is-final-section="isFinalSection() || isInFooter"
                :delay-animation="animationDelay(index)"
    >
        <template #animationContent>

            <div :class="['navbar-item', selected(page)]">
                <!-- HOME -->
                <svg v-if="showSeparator" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="slash icon">
                        <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M7.0744 22.4L15.6456 1.59998H17.8088L9.236 22.4H7.0744Z" fill="#8F94A3"/>
                    </g>
                </svg>
                <a v-if="page.type === 'home'" @click="(evt) => homeNavigation(evt)" :class="['btn', style, active(page)]">{{ pageTitle(page.name) }}</a>

                <!-- file, mailto, link -->
                <a v-else-if="['file', 'mailto', 'link'].includes(page.type)"
                   :href="page.type === 'mailto' ? ('mailto:' + page.settings.link.url) : page.settings.link.url"
                   :target="page.settings.link.openInNewTab ? '_blank' : ''"
                   rel="nofollow noopener"
                   :data-text="pageTitle(page.name)"
                   :class="['btn', style]">{{pageTitle(page.name)}}</a>

                <!-- default -->
                <a v-else @click="(evt) => navigationRef(evt, page)"
                   :class="['btn', style, active(page)]"
                   :data-text="page.name">
                    {{pageTitle(page.name)}}
                </a>

            </div>

        </template>
    </AnimationWrapper>
</template>

<script>
    import AnimationWrapper from "../../../../common/components/AnimationWrapper.vue";
    import {NavigationStyleEnum} from "../../../../common/enums.js";

    export default {
        name: "GetHeroNavigationItem",
        props: ['page', 'index', 'isInFooter'],
        components: {
            AnimationWrapper
        },
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
            showSeparator() {
                return this.index > 0
                    && this.portfolio.templateSettings.navigation.style === NavigationStyleEnum.FORWARD_SLASH
            },
            homeIsVisible() {
                return this.$store.getters.isCaseStudy
                    || (!this.portfolio.templateSettings.theme.includes("theme_one") && !this.portfolio.templateSettings.theme.includes("theme_two"));
            },
            socialLinks() {
                return this.$store.state.view.user.socialLinks ? this.$store.state.view.user.socialLinks : this.portfolio.socialLinks
            },
            style() {
                return this.portfolio.templateSettings.navigation.navigationItems.button.style
            },
        },
        methods: {
            selected(page) {
                return this.$store.state.view.activePage === page.shortId ? 'selected' : ''
            },
            active(page) {
                return this.$store.state.view.activePage === page.shortId ? 'active' : ''
            },
            rootIsOwnDomain() {
                return this.portfolio.ownDomainAddress && location.origin.includes(this.portfolio.ownDomainAddress);
            },
            homeNavigation(evt) {
                evt.preventDefault();
                const homeRoute = this.rootIsOwnDomain()
                    ? "/"
                    : `/${this.portfolio.shortId}`
                this.$router.push(homeRoute)
                this.$store.state.view.navOpen = false;
            },
            navigationRef(evt, page) {
                evt.preventDefault();
                const pageRoute = this.rootIsOwnDomain()
                    ? `/p/${page.shortId}`
                    : `/p/${this.portfolio.shortId}/${page.shortId}`
                this.$router.push(pageRoute)
                this.$store.state.view.navOpen = false;
            },
            pageTitle(title) {
                return this.portfolio.templateSettings.navigation.navigationItems.button.upperCase
                    ? title.toUpperCase()
                    : title
            },
            isFinalSection() {
                return !!(this.portfolio.templateSettings.theme.includes("theme_four") ||
                    this.portfolio.templateSettings.theme.includes("theme_nine") ||
                    this.portfolio.templateSettings.theme.includes("theme_default"));
            },
            animationDelay(index) {
                let delayBy = Math.round((index ? index * 0.1 : 0) * 100) / 100;
                if(this.socialLinks && this.portfolio.templateSettings.theme.includes('theme_one')) {
                    delayBy += 0.6 + this.socialLinks.length * 0.1;
                }
                if(!this.homeIsVisible && !this.portfolio.templateSettings.theme.includes('theme_two') && this.socialLinks) {
                    delayBy -= 0.1;
                }
                return delayBy;
            }
        },
    }
</script>

<style lang="scss" scoped>
@import "@/styles/layouts/new-portfolio-layout/components/navbar-item";
</style>