<template>
    <div class="mockup" @click="$emit('click')">
        <div class="ufo-mockup--display">
            <div class="ufo-mockup--frame">
                <Mockup :image-src="defaultImageSrc"
                        :svg-src="`/mockups/mockup--${mockupName}.svg`"
                        :image-loaded="imageLoaded ? imageLoaded($refs.img) : null"
                        :data-zoomable="setLightbox ? setLightbox : null"
                        :altTag="altTag"
                        :media="media"
                        :index="index"
                        :class="!media.frame || media?.frame === 'no-mockup' && forcedAspectRatio ? `aspect-ratio-${forcedAspectRatio}` : ''"
                        :allow-animation="allowAnimation"
                        :shadow="isShadowEnabled"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AnimationWrapper from "../../../scripts/common/components/AnimationWrapper.vue";
import InlineSvg from "vue-inline-svg";
import Mockup from "@/scripts/common/components/Mockup.vue";

export default {
    name: "MockupContainer",
    components: {
        Mockup,
        AnimationWrapper,
        InlineSvg
    },
    props: [
        'media',
        'imageLoaded',
        'setLightbox',
        'index',
        'forcedAspectRatio',
        'allowAnimation',
    ],
    emits: ['click'],
    computed: {
        defaultImageSrc() {
            if(!this.media.croppedImageUrl && this.media.url.includes('/example')) {
                if (this.media.url.includes('suggestion-wireframes')) {
                    if(this.media.frame?.includes('desktop') || this.media.frame?.includes('monitor')) {
                        return '/example_project_images/v2/suggestion-wireframes-desktop.png'
                    }
                } else if (this.media.url.includes('gallery-sample-tall')) {
                    if(this.media.frame?.includes('desktop') || this.media.frame?.includes('monitor')) {
                        return this.media.url.replace('tall', 'desktop').replace('jpg', 'png')
                    } else if (this.media.frame?.includes('tablet')) {
                        return  this.media.url.replace('tall', 'tablet').replace('jpg', 'png')
                    }
                }
                return this.media.url;
            }
            else {
                return !!this.media.croppedImageUrl ? this.media.croppedImageUrl : this.media.url
            }
        },
        altTag() {
            return !!this.media.altTag ? this.media.altTag : 'Image gallery item'
        },
        mockupName() {
            return this.media.frame ? this.media.frame.replace('--no-shadow', '').replace('--shadow', '') : ''
        },
        isShadowEnabled() {
            return this.media.frame.includes('--shadow') &&
                !this.media.frame.includes('outline') &&
                !this.media.frame.includes('realistic')
         }
    }
}
</script>

<style scoped lang="scss">

.img-overlay-wrap {
    position: relative;
    display: inline-block;
    transition: transform 150ms ease-in-out;
}

.img-overlay-wrap .img-scroll {
    display: block;
    width: auto;
    aspect-ratio: 744 / 1133;

    img {
        aspect-ratio: 744 / 1133;
    }
}

.img-overlay-wrap svg {
    height: 100%;
    position: absolute;
    width: 111%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mockup {
    height: 100%;
    width: auto;
    .ufo-mockup--display {
        height: 100%;
        .ufo-mockup--frame {
            height: 100%;
        }
    }
}
</style>
