<template>
    <OverlayingSidebarPanel @save="triggerSave()" @cancel="triggerCancel()" @close="triggerClose()">
        <div class="ufo-sepa--cop">
            <div class="ufo-sepa--cop-info">
                <InputField :label="'Link Name'"
                             :placeholder="'e.g.: Contact me'"
                             :max-length="24"
                             :has-error="!!pageDetails.name && pageDetails.name.length === 24"
                             :value="pageDetails.name"
                             v-model="pageDetails.name"
                             dark-scheme
                >
                    <template #alert><span v-if="!!pageDetails.name && pageDetails.name.length === 24" class="alert">You've reached the 24-character limit</span></template>
                </InputField>
                <InputField :label="'Email Address'"
                             :placeholder="'example@domain.com'"
                             :value="pageDetails.link.url"
                             v-model="pageDetails.link.url"
                             dark-scheme
                />
            </div>
        </div>
    </OverlayingSidebarPanel>
</template>

<script>
    import OverlayingSidebarPanel from "../components/OverlayingSidebarPanel.vue";
    import {TrackPageView} from "../../../store/actions/PortfolioActions.js";
    import InputField from "../../components/Input.vue";
    import {PagesPanelTypesEnum} from "../../enums.js";

    export default  {
        name: 'edit-mailto-link',
        components: {OverlayingSidebarPanel, InputField},
        emits: ["save", "cancel", "close"],
        beforeMount() {
            if(this.$route.query.add) {
                this.pageDetails.name = "Email Me";
                this.pageDetails.link.url = this.$store.state.user.contactEmail;
                this.$store.state.sidePanelSettings = {
                    ...this.$store.state.sidePanelSettings,
                    newPageDetails:this.pageDetails
                }
            } else {
                this.pageDetails.name = this.$store.state.portfolioPages.find(e => e.shortId === this.$route.query.edit).name
                this.pageDetails.link.url = this.$store.state.portfolioPages.find(e => e.shortId === this.$route.query.edit).settings.link.url;
            }
        },
        mounted() {
            this.$store.commit("updateSidePanel",{
                ...this.$route.meta.sidePanelSettings,
                title: `${this.$route.query.add ? 'Add' : 'Edit'} mailto link`,
                allowSave: this.$route.query.add,
            });
            TrackPageView(`/home${this.$route.fullPath}`, `Mailto link` );
        },
        unmounted() {
            this.$store.commit("updateSidePanel",{
                panel: PagesPanelTypesEnum.MAIN_PANEL,
                hideBackButton: false,
                title: 'Pages',
                hideActionButtons: true
            });
        },
        data() {
            return {
                pageDetails: {
                    name: null,
                    link: {
                        url: null,
                        openInNewTab: true
                    }
                }
            }
        },
        watch: {
            pageDetails: {
                handler(){
                    this.updateState();
                },
                deep: true
            }
        },
        methods: {
            updateState() {
                // TODO: add validation
                // let formattedURL = "";
                // if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.url) && this.url.indexOf('/@') < 0) {
                //     formattedURL = this.url.indexOf('mailto:') < 0 ? `mailto:${this.url}` : this.url
                // } else {
                //     formattedURL = this.url && !/^(f|ht)tps?:\/\//i.test(this.url) ? `https://${this.url}` : this.url;
                // }
                this.$store.state.sidePanelSettings.newPageDetails = this.pageDetails;
                if(!this.$route.query.add) {
                    this.$store.state.portfolioPages.find(e => e.shortId === this.$route.query.edit).name = this.pageDetails.name;
                    this.$store.state.portfolioPages.find(e => e.shortId === this.$route.query.edit).link = this.pageDetails.link;
                }
                this.$store.commit("updateSidePanel",{
                    allowSave: !!this.pageDetails.link.url
                        && this.pageDetails.link.url.length > 0
                        && !!this.pageDetails.name
                        && this.pageDetails.name.length > 0
                });
            },
            triggerSave() {
                this.$emit('save');
            },
            triggerCancel() {
                this.$emit('cancel');
            },
            triggerClose() {
                this.$emit('close');
            },
        }
    }
</script>

<style lang="scss" scoped>
    .ufo-input {
        margin-bottom: 20px;
    }
    .cb-l {
        font-family: var(--primarySans);
        font-size: 16px;
        line-height: 1;
        color: var(--carbon70);
        cursor: pointer;
        .ml-2 {
            padding-left: 6px;
        }
        input {
            cursor: pointer;
        }
    }
</style>