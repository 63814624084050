<template>
    <div class="adp-list-profile-container">
        <div class="header-wrapper">
            <Icon icon-name="adplist-logo" />
            <button class="adp-list-primary-button" @click="navigate">
                Book a session
                <Icon icon-name="book-session" />
            </button>
        </div>
        <div class="divider"></div>
        <div class="statistic-container">
            <p class="base-text">Community statistics</p>
            <div class="stats-wrapper">
                <div :key="stat.id" v-for="stat in Object.values(statItems)" class="stat">
                    <Icon :icon-name="stat.icon" />
                    <div class="details-wrapper">
                        <p v-if="stat.value !== null" class="stat-value">{{ stat.value }}{{ stat.extension }}</p>
                        <div v-else :style="{ width: (60 + stat.extension.length * 5) + 'px' }" class="skeleton-state-wrapper"><div class="skeleton-state"></div></div>
                        <p class="stat-name">{{ stat.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Icon from "@/scripts/common/Icon.vue";
import ApiHelper from "@/scripts/api/ApiHelper.js";
import ADPListAPIHelper from "@/scripts/api/ADPList/ADPListAPIHelper.js";
export default {
    name: "ADPListProfile",
    props: ['media'],
    components: {
        Icon
    },
    data() {
        return {
            statItems: {
                meetingsMinutes: {id:0, name:'Total mentoring time', icon:'mentoring-time-icon', value:null, extension:' mins'},
                meetingsCompleted:{id:1, name:'Sessions completed', icon:'sessions-icon', value:null, extension:''},
                averageAttendance:{id:2, name:'Average attendance', icon:'attendance-icon', value:null, extension:'%'},
            }
        }
    },
    watch: {
        'media.url'() {
            this.fetchProfileData()
        }
    },
    mounted() {
        if(this.media.url) {
            this.fetchProfileData();
        }
    },
    methods: {
        navigate() {
            window.open("https://adplist.org/mentors/" + this.media.url, '_blank')
        },
        async fetchProfileData() {
            try {
                const { data } = await ADPListAPIHelper.getAPDListProfile(this.media.url)
                Object.entries(data.data).forEach(([key, value]) => {
                    if (this.statItems[key]) {
                        this.statItems[key].value = value;
                    }
                });
            } catch (error) {
                console.error('Failed to load ADPList Profile data:', error);
            }
        }
    }
}
</script>

<style scoped lang="scss">
    @keyframes pulse {
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: 0.5;
        }
    }


    @media (max-width: 1000px) {
        .adp-list-profile-container {
            .statistic-container {
                align-items: flex-start;
                align-content: flex-start;
                flex-direction: column;
            }
        }
    }

    @media (max-width: 850px) {
        .adp-list-profile-container {
            .statistic-container {
                .stats-wrapper {
                    justify-content: space-between;
                    align-items: flex-start;
                    align-content: flex-start;
                    row-gap: 24px;
                }
            }
        }
    }

    @media (max-width: 500px) {
        .adp-list-profile-container {
            .statistic-container {
                .stats-wrapper {
                    align-items: flex-start;
                    align-content: flex-start;
                    gap: 24px 40px;
                }
            }
        }
    }


    .adp-list-profile-container {
        display: flex;
        padding: 30px;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        flex: 1 0 0;
        border-radius: 12px;
        border: 1px solid var(--asphalt30);
        background: #FFF;
        width: 100%;

        p {
            margin: 0;
        }

        .adp-list-primary-button {
            display: flex;
            padding: 12px 14px 12px 20px;
            align-items: center;
            gap: 4px;
            border-radius: 8px;
            background: #05051B;

            color: #FFF;
            font-family: "DM Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            outline: none;
            border: none;

            /* Add transition for background change */
            transition: background 150ms ease-in-out;
            
            &:hover {
                cursor: pointer;
                background: #087F7F;
            }
            
            &:active {
                background: #087F7F;
            }
        }

        .header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            row-gap: 20px;
            align-self: stretch;
            flex-wrap: wrap;
        }

        .divider {
            height: 1px;
            background: var(--asphalt20);
            width: 100%;

        }

        .statistic-container {
            display: flex;
            gap: 30px 40px;
            align-self: stretch;
            flex-wrap: wrap;
            align-items: center;

            .base-text {
                flex: 1 0 0;

                color: var(--asphalt100);
                font-variant-numeric: lining-nums proportional-nums;
                font-family: "DM Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 36px */
                text-align: left;

            }
            .stats-wrapper {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 40px;

                .stat {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .details-wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .skeleton-state-wrapper {
                            width: 80px;
                            height: 26px;
                            padding: 3px;

                            .skeleton-state {
                                height: 20px;
                                border-radius: 4px;
                                background: var(--asphalt20);
                                animation: pulse 1s infinite ease-in-out;
                            }
                        }

                        .stat-name {
                            color: var(--asphalt50);
                            font-variant-numeric: lining-nums proportional-nums;
                            font-family: "DM Sans";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%; /* 24px */
                        }
                        .stat-value {
                            color: var(--asphalt90);
                            font-variant-numeric: lining-nums proportional-nums;
                            font-family: "DM Sans";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
</style>