//MOBILE
let mobile_minimal_rounded = '/mockups/mockup--mobile--minimal--rounded.svg';
let mobile_minimal_squared = '/mockups/mockup--mobile--minimal--squared.svg';
let mobile_simple_light_rounded = '/mockups/mockup--mobile--simple--light--rounded.svg';
let mobile_simple_light_rounded_landscape = '/mockups/mockup--mobile--simple--light--rounded--landscape.svg';
let mobile_simple_dark_rounded = '/mockups/mockup--mobile--simple--dark--rounded.svg';
let mobile_simple_dark_rounded_landscap = '/mockups/mockup--mobile--simple--dark--rounded--landscape.svg';

let mobile_semi_realistic_light_rounded = '/mockups/mockup--mobile--semi-realistic--light--rounded.svg';
let mobile_semi_realistic_light_rounded_landscape = '/mockups/mockup--mobile--semi-realistic--light--rounded--landscape.svg';
let mobile_semi_realistic_dark_rounded = '/mockups/mockup--mobile--semi-realistic--dark--rounded.svg';
let mobile_semi_realistic_dark_rounded_landcape = '/mockups/mockup--mobile--semi-realistic--dark--rounded--landscape.svg';

let mobile_outline_light_rounded = '/mockups/mockup--mobile--outline--light--rounded.svg';
let mobile_outline_light_rounded_landscape = '/mockups/mockup--mobile--outline--light--rounded--landscape.svg';
let mobile_outline_dark_rounded = '/mockups/mockup--mobile--outline--dark--rounded.svg';
let mobile_outline_dark_rounded_landcape = '/mockups/mockup--mobile--outline--dark--rounded--landscape.svg';

let mobile_browser_light_rounded = '/mockups/mockup--mobile--browser--light--rounded.svg';
let mobile_browser_light_rounded_landscape = '/mockups/mockup--mobile--browser--light--rounded--landscape.svg';
let mobile_browser_dark_rounded = '/mockups/mockup--mobile--browser--dark--rounded.svg';
let mobile_browser_dark_rounded_landcape = '/mockups/mockup--mobile--browser--dark--rounded--landscape.svg';
let mobile_browser_light_squared = '/mockups/mockup--mobile--browser--light--squared.svg';
let mobile_browser_light_squared_landscape = '/mockups/mockup--mobile--browser--light--squared--landscape.svg';
let mobile_browser_dark_squared = '/mockups/mockup--mobile--browser--dark--squared.svg';
let mobile_browsere_dark_rounded_landcape = '/mockups/mockup--mobile--browser--dark--squared--landscape.svg';

//TABLET
let tablet_minimal_rounded = '/mockups/mockup--tablet--minimal--rounded.svg';
let tablet_minimal_squared = '/mockups/mockup--tablet--minimal--squared.svg';
let tablet_simple_light_rounded = '/mockups/mockup--tablet--simple--light--rounded.svg';
let tablet_simple_light_rounded_landscape = '/mockups/mockup--tablet--simple--light--rounded--landscape.svg';
let tablet_simple_dark_rounded = '/mockups/mockup--tablet--simple--dark--rounded.svg';
let tablet_simple_dark_rounded_landscap = '/mockups/mockup--tablet--simple--dark--rounded--landscape.svg';

let tablet_semi_realistic_light_rounded = '/mockups/mockup--tablet--semi-realistic--light--rounded.svg';
let tablet_semi_realistic_light_rounded_landscape = '/mockups/mockup--tablet--semi-realistic--light--rounded--landscape.svg';
let tablet_semi_realistic_dark_rounded = '/mockups/mockup--tablet--semi-realistic--dark--rounded.svg';
let tablet_semi_realistic_dark_rounded_landcape = '/mockups/mockup--tablet--semi-realistic--dark--rounded--landscape.svg';

let tablet_outline_light_rounded = '/mockups/mockup--tablet--outline--light--rounded.svg';
let tablet_outline_light_rounded_landscape = '/mockups/mockup--tablet--outline--light--rounded--landscape.svg';
let tablet_outline_dark_rounded = '/mockups/mockup--tablet--outline--dark--rounded.svg';
let tablet_outline_dark_rounded_landcape = '/mockups/mockup--tablet--outline--dark--rounded--landscape.svg';

let tablet_browser_light_rounded = '/mockups/mockup--tablet--browser--light--rounded.svg';
let tablet_browser_light_rounded_landscape = '/mockups/mockup--tablet--browser--light--rounded--landscape.svg';
let tablet_browser_dark_rounded = '/mockups/mockup--tablet--browser--dark--rounded.svg';
let tablet_browser_dark_rounded_landcape = '/mockups/mockup--tablet--browser--dark--rounded--landscape.svg';
let tablet_browser_light_squared = '/mockups/mockup--tablet--browser--light--squared.svg';
let tablet_browser_light_squared_landscape = '/mockups/mockup--tablet--browser--light--squared--landscape.svg';
let tablet_browser_dark_squared = '/mockups/mockup--tablet--browser--dark--squared.svg';
let tablet_browsere_dark_rounded_landcape = '/mockups/mockup--tablet--browser--dark--squared--landscape.svg';

//DESKTOP
let desktop_minimal_rounded = '/mockups/mockup--desktop--minimal--rounded.svg';
let desktop_minimal_squared = '/mockups/mockup--desktop--minimal--squared.svg';

let desktop_simple_light_rounded = '/mockups/mockup--desktop--simple--light--rounded.svg';
let desktop_simple_dark_rounded = '/mockups/mockup--desktop--simple--dark--rounded.svg';

let desktop_semi_realistic_light_rounded = '/mockups/mockup--desktop--semi-realistic--light--rounded.svg';
let desktop_semi_realistic_dark_rounded = '/mockups/mockup--desktop--semi-realistic--dark--rounded.svg';

let desktop_outline_light_rounded = '/mockups/mockup--desktop--outline--light--rounded.svg';
let desktop_outline_dark_rounded = '/mockups/mockup--desktop--outline--dark--rounded.svg';

let desktop_browser_light_rounded = '/mockups/mockup--desktop--browser--light--rounded.svg';
let desktop_browser_dark_rounded = '/mockups/mockup--desktop--browser--dark--rounded.svg';
let desktop_browser_light_squared = '/mockups/mockup--desktop--browser--light--squared.svg';
let desktop_browser_dark_squared = '/mockups/mockup--desktop--browser--dark--squared.svg';

//Monitor
let monitor_minimal_rounded = '/mockups/mockup--monitor--minimal--rounded.svg';
let monitor_minimal_squared = '/mockups/mockup--monitor--minimal--squared.svg';

let monitor_simple_light_rounded = '/mockups/mockup--monitor--simple--light--rounded.svg';
let monitor_simple_dark_rounded = '/mockups/mockup--monitor--simple--dark--rounded.svg';

let monitor_semi_realistic_light_rounded = '/mockups/mockup--monitor--semi-realistic--light--rounded.svg';
let monitor_semi_realistic_dark_rounded = '/mockups/mockup--monitor--semi-realistic--dark--rounded.svg';

let monitor_outline_light_rounded = '/mockups/mockup--monitor--outline--light--rounded.svg';
let monitor_outline_dark_rounded = '/mockups/mockup--monitor--outline--dark--rounded.svg';

let monitor_browser_light_rounded = '/mockups/mockup--monitor--browser--light--rounded.svg';
let monitor_browser_dark_rounded = '/mockups/mockup--monitor--browser--dark--rounded.svg';
let monitor_browser_light_squared = '/mockups/mockup--monitor--browser--light--squared.svg';
let monitor_browser_dark_squared = '/mockups/mockup--monitor--browser--dark--squared.svg';

export const mockups = {
    mobile: {
        minimal: {
            1: { settings:{color:'', corner:'rounded', position:'portrait'}, svg: mobile_minimal_rounded },
            2: { settings:{color:'', corner:'squared', position:'portrait'}, svg: mobile_minimal_squared },
        },
        simple: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: mobile_simple_light_rounded },
            2: { settings:{color:'light', corner:'rounded', position:'landscape'}, svg: mobile_simple_light_rounded_landscape},
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg: mobile_simple_dark_rounded},
            4: { settings:{color:'dark', corner:'rounded', position:'landscape'}, svg: mobile_simple_dark_rounded_landscap},
        },
        //TODO: add later
        realistic: {},
        semi_realistic: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: mobile_semi_realistic_light_rounded },
            2: { settings:{color:'light', corner:'rounded', position:'landscape'}, svg: mobile_semi_realistic_light_rounded_landscape},
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg:mobile_semi_realistic_dark_rounded},
            4: { settings:{color:'dark', corner:'rounded', position:'landscape'}, svg: mobile_semi_realistic_dark_rounded_landcape},
        },
        outline: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: mobile_outline_light_rounded },
            2: { settings:{color:'light', corner:'rounded', position:'landscape'}, svg: mobile_outline_light_rounded_landscape},
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg:mobile_outline_dark_rounded},
            4: { settings:{color:'dark', corner:'rounded', position:'landscape'}, svg: mobile_outline_dark_rounded_landcape},
        },
        browser: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: mobile_browser_light_rounded },
            2: { settings:{color:'light', corner:'rounded', position:'landscape'}, svg: mobile_browser_light_rounded_landscape},
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg:mobile_browser_dark_rounded},
            4: { settings:{color:'dark', corner:'rounded', position:'landscape'}, svg: mobile_browser_dark_rounded_landcape},

            5: { settings:{color:'light', corner:'squared', position:'portrait'}, svg: mobile_browser_light_squared },
            6: { settings:{color:'light', corner:'squared', position:'landscape'}, svg: mobile_browser_light_squared_landscape},
            7: { settings:{color:'dark', corner:'squared', position:'portrait'}, svg: mobile_browser_dark_squared},
            8: { settings:{color:'dark', corner:'squared', position:'landscape'}, svg: mobile_browsere_dark_rounded_landcape},
        },
    },
    tablet: {
        minimal: {
            1: { settings:{color:'', corner:'rounded', position:'portrait'}, svg: tablet_minimal_rounded },
            2: { settings:{color:'', corner:'squared', position:'portrait'}, svg: tablet_minimal_squared },
        },
        simple: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: tablet_simple_light_rounded },
            2: { settings:{color:'light', corner:'rounded', position:'landscape'}, svg: tablet_simple_light_rounded_landscape},
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg: tablet_simple_dark_rounded},
            4: { settings:{color:'dark', corner:'rounded', position:'landscape'}, svg: tablet_simple_dark_rounded_landscap},
        },
        //TODO: add later
        realistic: {},
        semi_realistic: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: tablet_semi_realistic_light_rounded },
            2: { settings:{color:'light', corner:'rounded', position:'landscape'}, svg: tablet_semi_realistic_light_rounded_landscape},
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg:tablet_semi_realistic_dark_rounded},
            4: { settings:{color:'dark', corner:'rounded', position:'landscape'}, svg: tablet_semi_realistic_dark_rounded_landcape},
        },
        outline: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: tablet_outline_light_rounded },
            2: { settings:{color:'light', corner:'rounded', position:'landscape'}, svg: tablet_outline_light_rounded_landscape},
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg:tablet_outline_dark_rounded},
            4: { settings:{color:'dark', corner:'rounded', position:'landscape'}, svg: tablet_outline_dark_rounded_landcape},
        },
        browser: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: tablet_browser_light_rounded },
            2: { settings:{color:'light', corner:'rounded', position:'landscape'}, svg: tablet_browser_light_rounded_landscape},
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg:tablet_browser_dark_rounded},
            4: { settings:{color:'dark', corner:'rounded', position:'landscape'}, svg: tablet_browser_dark_rounded_landcape},

            5: { settings:{color:'light', corner:'squared', position:'portrait'}, svg: tablet_browser_light_squared },
            6: { settings:{color:'light', corner:'squared', position:'landscape'}, svg: tablet_browser_light_squared_landscape},
            7: { settings:{color:'dark', corner:'squared', position:'portrait'}, svg: tablet_browser_dark_squared},
            8: { settings:{color:'dark', corner:'squared', position:'landscape'}, svg: tablet_browsere_dark_rounded_landcape},
        }
    },
    desktop: {
        minimal: {
            1: { settings:{color:'', corner:'rounded', position:'portrait'}, svg: desktop_minimal_rounded },
            2: { settings:{color:'', corner:'squared', position:'portrait'}, svg: desktop_minimal_squared },
        },
        simple: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: desktop_simple_light_rounded },
            2: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg: desktop_simple_dark_rounded},
        },
        realistic: {},
        semi_realistic: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: desktop_semi_realistic_light_rounded },
            2: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg: desktop_semi_realistic_dark_rounded},
        },
        outline: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: desktop_outline_light_rounded },
            2: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg: desktop_outline_dark_rounded},
        },
        browser: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: desktop_browser_light_rounded },
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg:desktop_browser_dark_rounded},
            5: { settings:{color:'light', corner:'squared', position:'portrait'}, svg: desktop_browser_light_squared },
            7: { settings:{color:'dark', corner:'squared', position:'portrait'}, svg: desktop_browser_dark_squared},
        }
    },
    monitor: {
        minimal: {
            1: { settings:{color:'', corner:'rounded', position:'portrait'}, svg: monitor_minimal_rounded },
            2: { settings:{color:'', corner:'squared', position:'portrait'}, svg: monitor_minimal_squared },
        },
        simple: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: monitor_simple_light_rounded },
            2: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg: monitor_simple_dark_rounded},
        },
        realistic: {},
        semi_realistic: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: monitor_semi_realistic_light_rounded },
            2: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg: monitor_semi_realistic_dark_rounded},
        },
        outline: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: monitor_outline_light_rounded },
            2: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg: monitor_outline_dark_rounded},
        },
        browser: {
            1: { settings:{color:'light', corner:'rounded', position:'portrait'}, svg: monitor_browser_light_rounded },
            3: { settings:{color:'dark', corner:'rounded', position:'portrait'}, svg:monitor_browser_dark_rounded},
            5: { settings:{color:'light', corner:'squared', position:'portrait'}, svg: monitor_browser_light_squared },
            7: { settings:{color:'dark', corner:'squared', position:'portrait'}, svg: monitor_browser_dark_squared},
        }
    }
}