<template>
    <div class="scrollable-container">
        <slot></slot>
    </div>
</template>

<script>

    export default {
        name: "ScrollableContainer",
    }
</script>

<style lang="scss" scoped>
    .scrollable-container {
        position: relative;
        flex: 2;
        overflow-y: auto;
    }
</style>