<template>
    <!-- Old layout -->
    <div v-if="templateSettings.theme.includes('profile_')" class="ufo--portfolio-container" :style="[getTextStyles]">
        <div class="ufo--portfolio-container">
            <div :class="['ufo--portfolio-page', 'profile-page', 'profile-subpage', templateSettings.theme, templateSettings.showProfilePicture ? 'profile--hp' : '']">
                <div v-if="templateSettings.theme === 'profile_theme_default_old' || (templateSettings.theme !== 'profile_theme_one' && templateSettings.theme !== 'profile_theme_two')"
                     :class="['grid', 'ufo--page-hero', templateSettings.theme]">
                    <div :class="['ufo--page-hero-content', templateSettings.showProfilePicture && (portfolio.image.url || user.image.url && !portfolio.image.url) ? 'has-picture' : 'no-profile-picture']">
                        <PortfolioHeroHelper />
                    </div>
                </div>
                <div class="ufo--page-content">
                    <GetHeroNavigation v-if="!templateSettings.theme || templateSettings.theme && templateSettings.theme.includes('profile_theme_default')" :show-home-button="true" />
                    <div :class="['ufo--about-page',
                                'ufo--about-page-' + templateSettings.theme,
                                portfolio.about && (portfolio.about.description || portfolio.about.image.url) ? 'legacy' : '']"
                    >
                        <div class="ufo--about-header" v-if="templateSettings.theme.includes('theme_one') || templateSettings.theme.includes('theme_two')">
                            <div class="grid">
                                <div class="about-title">
                                    <div class="about-title-container">
                                        <AnimationWrapper
                                            :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                            :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                        >
                                            <template #animationContent>
                                                <h1 class="title">{{project.name ? project.name : ''}}</h1>
                                            </template>
                                        </AnimationWrapper>
                                    </div>
                                </div>
                                <AnimationWrapper
                                    :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                    :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                                >
                                    <template #animationContent>
                                        <div class="back" @click="navigateToPage()">⟵ Back</div>
                                    </template>
                                </AnimationWrapper>
                            </div>
                        </div>
                        <div class="ufo--about-legacy-wrapper" v-if="showLegacyAboutPage()">
                            <AnimationWrapper
                                :animation-style="$store.getters.animationSettings ? $store.getters.animationSettings.animationStyle : null"
                                :animation-speed="$store.getters.animationSettings ? $store.getters.animationSettings.animationSpeed : null"
                            >
                                <template #animationContent>
                                    <div class="grid ufo--about-content">
                                        <div v-if="portfolio.about.image.url" class="about-picture">
                                            <img :src="portfolio.about.image.url" />
                                        </div>
                                        <div :class="['about-text', !portfolio.about.image.url ? 'no-pic' : '']" v-html="legacyAboutText()"></div>
                                    </div>
                                </template>
                            </AnimationWrapper>
                        </div>
                    </div>
                    <div v-if="project.sections" class="ufo--about-content ufo--page-sections">
                        <SectionLoader :default-image-alt="''" :sections="project.sections"/>
                    </div>
                </div>
                <PublicPageFooter />
            </div>
        </div>
    </div>
    <!-- New Layout -->
    <div v-else
         :class="['ufo--portfolio-page', templateSettings.theme]"
         :style="[getTextStyles] "
    >
        <PortfolioHeroHelper />
        <div v-if="templateSettings.theme === 'theme_one' || templateSettings.theme === 'theme_two'"
             :class="['ufo--about-page', 'ufo--about-page-' + templateSettings.theme]"
        >
            <div class="ufo--about-header">
                <div class="grid">
                    <div class="about-title">
                        <div class="about-title-container">
                            <h1 class="title">{{ project.name ? project.name : '' }}</h1>
                        </div>
                    </div>
                    <div class="back" @click="navigateToPage()">⟵ Back</div>
                </div>
            </div>
        </div>
        <div class="ufo--page-content ufo--home-page-content">
            <div class="ufo--page-sections ufo--about-page-sections" :class="{'empty-list':project.sections.length < 1}">
                <SectionLoader :sections="project.sections" :default-image-alt="''" />
            </div>
        </div>
    </div>
</template>

<script>
    import SectionLoader from "./sections/SectionLoader.vue";
    import {useHead} from "@unhead/vue";
    import PortfolioHeroHelper from "./themes/_helper/PortfolioHeroHelper.vue";
    import PortfolioFooter from "./common/PortfolioFooter.vue";
    import GetHeroNavigation from "./common/hero/GetHeroNavigation.vue";
    import PublicPageFooter from "./common/PublicPageFooter.vue";
    import AnimationWrapper from "../../common/components/AnimationWrapper.vue";

    let myPageHead = null;

    export default {
        name: "CustomPage",
        components: {
            AnimationWrapper,
            GetHeroNavigation,
            PublicPageFooter, PortfolioFooter, PortfolioHeroHelper, SectionLoader},
        mounted() {

            let head = {
                meta:[],
                script:[]
            }

            if(document.title !==  this.portfolio.name + (this.project.type !== 'home' && this.project.name ? (' - ' + this.project.name) : '')) {
                head.title = this.portfolio.name + (this.project.type !== 'home' && this.project.name ? (' - ' + this.project.name) : '');
            }

            myPageHead = useHead(head)
        },
        beforeUpdate() {
            myPageHead.patch({
                title: this.portfolio.name + (this.project.type !== 'home' && this.project.name ? (' - ' + this.project.name) : ''),
            })
        },
        unmounted() {
            myPageHead.dispose()
        },
        methods: {
            goBack() {
              this.$router.back()
            },
            //TODO: refactor
            navigateToPage(page) {
                let currentLocation = location.href.replace(location.search, "").replace('/p/', '/');
                if(window.location.pathname.includes('/contact') || (window.location.pathname.includes('/about') || (window.location.pathname.includes('/p/')))
                ){
                    window.location = currentLocation.substring(0, currentLocation.lastIndexOf('/')) + (page ? '/' + page : '');
                }
                else {
                    window.location = currentLocation + (currentLocation[currentLocation.length - 1] === '/' ? '' : '/') + (page ? page : '');
                }
            },
            showLegacyAboutPage() {
                const legacyPage = this.project.sections.length === 0
                    && this.project.type === 'about'
                    && this.portfolio.about
                    && (this.portfolio.about.description || this.portfolio.about.image.url)

                if(legacyPage) {
                    import("@/styles/layouts/_about-me-page.scss");
                }
                return legacyPage
            },
            legacyAboutText() {
                return this.portfolio.about.description.includes("rel=\"noopener noreferrer nofollow\"")
                    ? this.portfolio.about.description
                    : this.refactoredDesc
            },
        },
        computed: {
            portfolio() {
                return this.$store.state.view.portfolio
            },
            project() {
                return this.$store.state.view.project
            },
            templateSettings() {
                return this.portfolio.templateSettings
            },
            user() {
                return this.$store.state.view.user
            },
            settings() {
                return this.portfolio.templateSettings
            },
            refactoredDesc() {
                return this.portfolio.about.description.replace("<a href=", "<a rel=\"nofollow\" href=")
            },
            sidebarBackgroundColor() {
                return {
                    '--sidebarBgColor': this.portfolio.templateSettings.hero && this.portfolio.templateSettings.hero.sidebarSettings
                        ? this.portfolio.templateSettings.hero.sidebarSettings.backgroundColor
                        : null
                }
            },
            footerDisabledInThemes() {
                return ['theme_four', 'profile_theme_four', 'theme_nine', 'profile_theme_nine', 'theme_default', 'profile_theme_default']
            },
            portfolioTemplate() {
                return this.portfolio.templateSettings.theme.replace("_old", "");
            },
            getTextStyles() {
                return this.$store.getters.getAllTextStylesInCss;
            },
            isFooterEnabled() {
                let avalibleInCaseStudy = this.project.type === 'caseStudy' && this.portfolio.settings.showFooterOnProjects && this.portfolio.portfolioFooter
                let avalibleInPublicPage = this.project.type !== 'caseStudy' && this.portfolio.settings.showFooterOnPortfolio && !this.footerDisabledInThemes.includes(this.portfolioTemplate) && this.portfolio.portfolioFooter;
                return avalibleInCaseStudy || avalibleInPublicPage
            }
        }
    }
</script>

<style lang="scss" scoped>
.ufo--page-hero-content {
    position: relative;
}

.profile_theme_default .ufo--page-hero-content {
    position: sticky;
}

 .ufo--page-sections {
     &.empty-list {
         min-height: 100vh;
         display: flex;
         flex-direction: column;
         justify-content: flex-end;
     }
 }
</style>